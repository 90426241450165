<template>
  <div>
    <div class="cont1">
      <!-- <div class="main-cont top-wrap">
        <div class="left-cont">
          <div class="text1">数智售后SAAS系统</div>
          <div class="text2">智能系统，高效管理，线上线下融合解决方案</div>
        </div>
        <img
          class="top-img"
          src="/images/banner-service.png"
          alt=""
        />
      </div> -->
      <div class="container">
        <div>
          <h1>数智售后SASS系统</h1>
          <p>智能系统｜售后管理｜线上线下融合解决方案</p>
        </div>
        <img src="../assets/svg/sass.svg" />
      </div>
    </div>
    <div class="cont2">
      <div class="main-cont">
        <div class="ms letterSpacing6">给企业用户最优质的用户体验</div>
        <p class="msSubTitle">
          基于厂家的复合需求，定制最专业，个性化的售后服务平台。
        </p>
        <div class="worker-wrap">
          <div class="character">
            <img class="character-img" src="/images/sass_icon1.png" alt="" />
            <div class="character-info">
              <p class="tit">高效</p>
              <div>线上高效沟通，少折返，缩短安装维修时长。</div>
            </div>
          </div>
          <div class="character">
            <img class="character-img" src="/images/sass_icon2.png" alt="" />
            <div class="character-info">
              <p class="tit">智能</p>
              <div>智能下单，一键下单，满足多样化便捷的下单场景。</div>
            </div>
          </div>
          <div class="character">
            <img class="character-img" src="/images/sass_icon3.png" />
            <div class="character-info">
              <p class="tit">实时</p>
              <div>订单状态实时掌握，异常订单实时跟踪，时效全掌握。</div>
            </div>
          </div>
          <div class="character">
            <img class="character-img" src="/images/sass_icon4.png" />
            <div class="character-info">
              <p class="tit">专业</p>
              <div>
                常用师傅管理，设置常用师傅，快速分配优质师傅，履约更专业。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cont3">
      <div class="main-cont">
        <div class="ms letterSpacing6">专业的售后SAAS解决方案</div>
        <p class="msSubTitle">健身器材厂家定制下单平台</p>
        <div class="flex-liucheng" style="height: 110px; position: relative">
          <div
            style="
              border-top: 1px dashed rgb(204, 204, 204);
              position: absolute;
              top: 50%;
              width: 100%;
            "
          ></div>
          <div class="pointerWrap">
            <div class="flex flex-center flex-column">
              <div class="fs18 c666 mb15">用户下单</div>
              <div class="pointer">1</div>
            </div>
          </div>
          <div class="pointerWrap pointerWrap-extend">
            <div class="flex flex-center flex-column">
              <div class="pointer">2</div>
              <div class="fs18 c666 mt15">品牌商代理商接单</div>
            </div>
          </div>
          <div class="pointerWrap">
            <div class="flex flex-center flex-column">
              <div class="fs18 c666 mb15">分配师傅</div>
              <div class="pointer">3</div>
            </div>
          </div>
          <div class="pointerWrap pointerWrap-extend">
            <div class="flex flex-center flex-column">
              <div class="pointer">4</div>
              <div class="fs18 c666 mt15">师傅接单</div>
            </div>
          </div>
          <div class="pointerWrap">
            <div class="flex flex-center flex-column">
              <div class="fs18 c666 mb15">师傅履约</div>
              <div class="pointer">5</div>
            </div>
          </div>
          <div class="pointerWrap pointerWrap-extend">
            <div class="flex flex-center flex-column">
              <div class="pointer">6</div>
              <div class="fs18 c666 mt15">平台发工资</div>
            </div>
          </div>
        </div>
        <!-- <img
          src="/images/lc.db08c9de.png"
          style="width: 100%; margin-top: 62px"
        /> -->
      </div>
    </div>
    <!-- <div class="cont4">
      <div class="main-cont main-cont2">
        <div class="ms letterSpacing6">
          平台资金担保，七天到账，保障资金安全
        </div>
        <div
          class="flex-spacebetween"
          style="margin-top: 46px"
        >
          <img
            src="/images/dsk@2x.77692d8c.png"
            style="height: 382px"
          />
          <div style="width: 380px; margin-right: 106px; text-align: left">
            <p style="font-size: 24px">代收款</p>
            <p style="font-size: 16px">
              用户下单付款，平台代收款，线上把钱款实时收归企业
              账户保障线下离店场景支付合规性。
            </p>
          </div>
        </div>
        <div
          class="flex-spacebetween"
          style="margin-top: 80px"
        >
          <div style="text-align: left; width: 380px">
            <p style="font-size: 24px">代发工资</p>
            <p style="font-size: 16px">
              平台线上给师傅发放工资，少操心，账单完成七天后自动到账，资金更安全。
            </p>
          </div>
          <img
            src="/images/dfgz@2x.7c67ea61.png"
            style="height: 368px"
          />
        </div>
      </div>
    </div> -->
    <div class="cont7">
      <div class="main-cont">
        <div class="ms letterSpacing6">产品服务高效优质</div>
        <div class="sales-wrap">
          <div class="sales">
            <img class="sales-img" src="/images/sass_p7_1.png" alt="" />
            <div class="sales-tit">服务时效快</div>
            <div style="font-size: 16px">
              <span class="num">2H</span>
              快速响应
            </div>
          </div>
          <div class="sales">
            <img class="sales-img" src="/images/sass_p7_2.png" alt="" />
            <div class="sales-tit">网络覆盖广</div>
            <div style="font-size: 16px">
              <span class="num">360</span>
              全覆盖
            </div>
          </div>
          <div class="sales">
            <img class="sales-img" src="/images/sass_p7_3.png" alt="" />
            <div class="sales-tit">服务好评高</div>
            <div style="font-size: 16px">
              <span class="num">98%</span>
              满意度
            </div>
          </div>
          <div class="sales">
            <img class="sales-img" src="/images/sass_p7_4.png" alt="" />
            <div class="sales-tit">服务能力无盲区</div>
            <div style="font-size: 16px">
              <span class="num">2861</span>
              地区无空白
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cont4">
      <div class="main-cont main-cont2">
        <div class="ms letterSpacing6">售后整体解决方案</div>
        <p class="msSubTitle">致力打造健身器材 按摩椅行业一流服务体系</p>
        <div>
          <img style="max-width: 100%" src="/images/sass_p5.png" alt="" />
        </div>
      </div>
    </div>
    <div class="cont6">
      <div class="main-cont">
        <div class="ms letterSpacing6">平台资金担保</div>
        <p class="msSubTitle">先服务后付款 免费质保服务</p>
        <div class="sales-wrap">
          <div class="sales">
            <img class="sales-img" src="/images/sass_p6_1.png" alt="" />
            <div class="sales-tit">安装/维修低</div>
            <div style="font-size: 16px">健身器材/按摩椅分别计费</div>
            <div style="margin-top: 24px">
              <span style="color: #4d7aff">可统一打包价</span><br />可按单计费
            </div>
          </div>
          <div class="sales">
            <img class="sales-img" src="/images/sass_p6_2.png" alt="" />
            <div class="sales-tit">周期短</div>
            <div style="font-size: 16px">当预约天/售后48H结单</div>
            <div style="margin-top: 24px">
              <span style="color: #4d7aff">时效高</span><br />时时可查
            </div>
          </div>
          <div class="sales">
            <img class="sales-img" src="/images/sass_p6_3.png" alt="" />
            <div class="sales-tit">账单清晰</div>
            <div style="font-size: 16px">按单展示明细金额</div>
            <div style="margin-top: 24px">
              <span style="color: #4d7aff">平台安全</span><br />先服务/月结算
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cont5" v-if="false">
      <div class="main-cont">
        <div class="ms letterSpacing6">售后SAAS服务价格方案</div>
        <div style="padding-top: 30px" class="table-wrap">
          <a-table
            class="table-style tr-bg1"
            :columns="columns1"
            :dataSource="data1"
            :pagination="false"
          >
          </a-table>
          <a-table
            class="table-style tr-bg2"
            :columns="columns1"
            :dataSource="data2"
            :pagination="false"
          >
          </a-table>
        </div>
        <div class="table-wrap">
          <a-table
            class="table-style tr-bg1"
            :columns="columns2"
            :dataSource="data3"
            :pagination="false"
          >
          </a-table>
          <a-table
            class="table-style tr-bg2"
            :columns="columns2"
            :dataSource="data4"
            :pagination="false"
          >
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      columns1: [
        {
          title: "师傅人数",
          dataIndex: "count",
        },
        {
          title: "价格（元）/年",
          dataIndex: "price",
        },
      ],
      columns2: [
        {
          title: "主要功能",
          dataIndex: "major",
        },
        {
          title: "功能说明",
          dataIndex: "desc",
        },
        {
          title: "是否支持",
          scopedSlots: {
            customRender: "isSupport",
          },
        },
      ],
      data1: [
        { count: 10, price: "￥1780.00" },
        { count: 20, price: "￥3480.00" },
        { count: 30, price: "￥5080.00" },
        { count: 50, price: "￥5080.00" },
        { count: 80, price: "￥12380.00" },
      ],
      data2: [
        { count: 100, price: "￥15080.00" },
        { count: 200, price: "￥29380.00" },
        { count: 300, price: "￥42080.00" },
        { count: 500, price: "￥67580.00" },
        { count: 1000, price: "￥121580.00" },
      ],
      data3: [
        { major: "多账号团队协作", desc: "商户/员工多角色账号" },
        { major: "订单管理", desc: "自动派单/异常/二次/评价" },
        { major: "配件管理", desc: "库存/出入库/统计/安全库存预警" },
        { major: "资料管理", desc: "说明书/安装视频/维修教程" },
        { major: "师傅管理", desc: "自定义价格/区域" },
        { major: "统计分析", desc: "统计监控各种数据" },
        { major: "风险预警", desc: "预警风险/高效处理" },
        { major: "能力评估", desc: "商户/城市/师傅多纬度评估" },
      ],
      data4: [
        { major: "代发薪资", desc: "薪资自动到账/更完全" },
        { major: "派单设置", desc: "自定义配置自动派单规则" },
        { major: "预警设置", desc: "自定义各项预警值" },
        { major: "师傅团队", desc: "师傅创建团队" },
        { major: "运营管理", desc: "统计分析/活动管理" },
        { major: "巡检管理", desc: "站点管理/自定义巡检周期	" },
        { major: "钱包管理", desc: "流水/对账单/发票" },
        { major: "系统设置", desc: "多项功能自定义配置" },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 1000px;
  margin: 0 auto;
}
.cont1 {
  width: 100%;
  padding: 90px 0 0;
  // background: linear-gradient(90deg, rgb(20, 155, 177), rgb(45, 49, 145));
  // background: url(/images/sass.png) top center no-repeat;
  background: #4d7aff; // url(/images/service.png) top center no-repeat;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  img {
    width: 540px;
  }
  h1 {
    font-size: 40px;
    margin-top: -90px;
  }
  p {
    font-size: 16px;
  }
  h1,
  p {
    color: #fff;
    text-align: left;
  }
}
.main-cont {
  width: 1024px !important;
  margin: 0 auto;
}
.main-cont2 {
  width: 1000px !important;
}
.top-wrap {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  .left-cont {
    text-align: left;
    color: #fff;
    .text1 {
      font-size: 48px;
      font-weight: bold;
    }
    .text2 {
      font-size: 24px;
      line-height: 60px;
    }
  }
  .top-img {
    width: 612px;
    height: auto;
    align-self: flex-end;
  }
}
.cont2 {
  .worker-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .character {
    width: 470px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 60px;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
  }
  .character-img {
    width: 100px;
  }
  .character-info {
    text-align: left;
    padding-left: 50px;
    font-size: 16px;
  }
  .tit {
    font-size: 24px;
  }
}
.cont3 {
  background-color: rgb(248, 248, 248);
  padding-bottom: 60px;
  .flex-liucheng {
    display: flex;
    justify-content: space-between;
    .pointerWrap-extend {
      display: flex;
      align-items: flex-end;
    }
    .pointerWrap {
      position: relative;
      z-index: 10;
    }
    .pointer {
      height: 24px;
      line-height: 24px;
      text-align: center;
      width: 24px;
      color: #fff;
      border: 4px solid #b9cbfe;
      font-size: 12px;
      background: #4d7aff;
      border-radius: 50%;
    }
    .flex-center {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .mb15 {
      margin-bottom: 15px;
    }
    .fs18 {
      font-size: 18px;
    }
    .mt15 {
      margin-top: 15px;
    }
  }
}
.cont4 {
  padding-bottom: 60px;
  background: rgb(248, 248, 248);
  .flex-spacebetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.cont5 {
  background-color: rgb(248, 248, 248);
  padding-bottom: 60px;
  .table-wrap {
    display: flex;
    .table-style {
      flex: 1;
      /deep/ th {
        background-color: rgb(4, 181, 170);
        font-size: 20px;
        color: #fff;
      }
      /deep/ td {
        font-size: 16px;
        color: #666;
      }
    }
    .tr-bg1 {
      /deep/ tr:nth-child(2n) {
        background: #fff;
      }
    }
    .tr-bg2 {
      /deep/ tr:nth-child(2n + 1) {
        background: #fff;
      }
    }
  }
}
.cont6 {
  background-color: #fff;
  .sales-wrap {
    display: flex;
    justify-content: space-between;
  }
  .sales {
    width: 310px;
    padding: 50px 30px;
    font-size: 16px;
    line-height: 30px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    background-color: #fff;
  }
  .sales-img {
    width: 200px;
  }
  .sales-tit {
    font-size: 24px;
    margin: 20px 0;
  }
  padding-bottom: 60px;
}
.cont7 {
  background-color: #fff;
  .sales-wrap {
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
  }
  .sales {
    width: 230px;
    padding: 40px 25px;
    font-size: 16px;
    line-height: 30px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    background-color: #fff;
  }
  .sales-img {
    width: 90px;
  }
  .sales-tit {
    font-size: 24px;
    margin: 40px 0;
  }
  .num {
    font-size: 36px;
    font-weight: bold;
    color: #4d7aff;
  }
  padding-bottom: 100px;
}
.ms {
  font-size: 36px;
  text-align: center;
  padding: 80px 0 20px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.65);
}
.letterSpacing6 {
  letter-spacing: 6px;
}
.msSubTitle {
  color: #666;
  font-size: 24px;
  text-align: center;
  margin: 0 0 80px;
}
</style>
